import * as mobx from "mobx";
import {
    EnumDocumentCreationType,
    EnumUserRole,
    FileResponse,
    Hierarchy,
    Knz4LSAbsenderModel,
    Knz4LSClient,
    Knz4LSIngBueroModel
} from "../../generated/BackendClient";
import { RadRepository } from "../../generated/RadRepository";
import { EntityCache, UserRepository } from "../../Rad/DAL";
import { ArrayAsMap, WrapObjects } from "../../Rad/DAL/WrapperTools";
import { Knz4LSObjekt } from "./Knz4LSObjekt";

export class Knz4Repository {
    private Dal = new Knz4LSClient();

    @mobx.observable
    public IsReadOnly = true;
    private AbsenderCache: EntityCache<Knz4LSAbsenderModel>;
    private IngBueroCache: EntityCache<Knz4LSIngBueroModel>;

    public constructor() {
        this.AbsenderCache = new EntityCache(RadRepository.Knz4LSAbsenderModel);
        this.IngBueroCache = new EntityCache(RadRepository.Knz4LSIngBueroModel);
        (async () => {
            const user = await UserRepository.GetCurrentUser();
            mobx.runInAction(() => {
                this.IsReadOnly = !user.currentUser.isAdmin && !user.allowedScopes.some((g) =>
                    [EnumUserRole.Knz4LSBenutzer, EnumUserRole.FachlicherAdmin].includes(g)
                );
            });
        })();
    }

    public async ExportListToExcel(): Promise<FileResponse> {
        return this.Dal.exportExcel();
    }

    public async GetKnz4LSObjektFolders(parent: Hierarchy): Promise<Hierarchy[]> {
        return this.Dal.getGroups(parent?.id);
    }

    public async GetKnz4LSObjekt(folder: Hierarchy): Promise<Knz4LSObjekt[]> {
        let result = await this.Dal.getItems(folder);
        return WrapObjects(result, Knz4LSObjekt);
    }

    public async ExportDocument(
        item: Knz4LSObjekt,
        type: EnumDocumentCreationType,
        absender?: Knz4LSAbsenderModel
    ): Promise<FileResponse> {
        let wf = new Knz4LSClient();
        return wf.createDocument(item.objectId, type, absender?.objectId);
    }

    public async GetUniqueId(): Promise<number> {
        return await this.Dal.getUniqueId();
    }

    public async GetFirmen(): Promise<Map<string, Knz4LSIngBueroModel>> {
        return this.IngBueroCache.GetAllMap();
    }

    public async GetAbsender(): Promise<Map<string, Knz4LSAbsenderModel>> {
        return this.AbsenderCache.GetAllMap();
    }

    public async GetDeletedObjekte(): Promise<Map<string, Knz4LSObjekt>> {
        let result = await this.Dal.getItemsDeleted();
        return ArrayAsMap(WrapObjects(result, Knz4LSObjekt));
    }

    public async GetAllKnz4LSObjektOhneFreigabe(): Promise<Map<string, Knz4LSObjekt>> {
        let result = await this.Dal.getItemsOhneFreigabe();
        return ArrayAsMap(WrapObjects(result, Knz4LSObjekt));
    }

    public async Search(searchwords: string): Promise<Map<string, Knz4LSObjekt>> {
        let result = await this.Dal.search(searchwords);
        return ArrayAsMap(WrapObjects(result, Knz4LSObjekt));
    }
}
