import * as mobx from "mobx";
import { observer } from "mobx-react";
import {
    DefaultButton, DialogFooter, Label,
    Link,
    MessageBar,
    MessageBarButton,
    MessageBarType,
    Pivot,
    PivotItem,
    PrimaryButton,
    Separator,
    TextField
} from "office-ui-fabric-react";
import * as React from "react";
import { Knz4LSBelege, Knz4LSFirmaControl } from ".";
import { LskRepository } from "../../DAL/LskRepository";
import { Knz4LSObjekt, Knz4Repository } from "../../Model/Knz4LS";
import { UserRepository } from "../../Rad/DAL";
import { RadVersionHistoryListEntity } from "../../Rad/tsx/Controls";
import { Bundeslaender, Utils, nameof } from "../../Utils";
import {
    EntityReferenceOfBaseUserModel,
    EntityReferenceOfKnz4LSIngBueroModel,
    EnumKnz4LSFreigabeStatus,
    EnumUserRole
} from "../../generated/BackendClient";
import {
    DBDialog,
    EasyCombobox,
    EasyDatePicker,
    EasyForm,
    EasyMaskedTextField,
    EasyNumberField,
    EasyTextField,
    HandleError,
    Header,
    PleaseWait,
    ShowUser,
    ValidationMessages
} from "../Generic";
import { CatchReactErrors, CatchReactErrorsMethod } from "../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class Knz4LSEditDialog extends React.Component<
    {
        Model: Knz4Repository;
        InitialModel: Knz4LSObjekt;
        hideDialog: boolean;
        closeDialog: (result: boolean, newModel: Knz4LSObjekt) => Promise<void>;
    },
    {
        Item: Knz4LSObjekt;
        IsLoading: boolean;
        MayUnsetDsvgo: boolean;
        MayApprove: boolean;
    }
> {
    private repository = LskRepository.Knz4LSObjekt;
    public readonly state = {
        Item: this.props.InitialModel.Clone(), // clone source object and dereference mobx properties
        IsLoading: true,
        ListItem: undefined,
        MayUnsetDsvgo: false,
        MayApprove: false
    };

    public async componentDidMount(): Promise<void> {
        const user = await UserRepository.GetCurrentUser();

        let item = this.props.InitialModel;
        if (item.objectId) {
            item = await this.repository.get(this.props.InitialModel.objectId);
        }
        this.setState({
            IsLoading: false,
            MayUnsetDsvgo: user.allowedScopes.some((g) => [EnumUserRole.FachlicherAdmin].includes(g)),
            MayApprove: item.objectId && user.currentUser.objectId !== item.createdBy?.objectId,
            Item: item
        });
    }

    public render(): JSX.Element {
        return (
            <DBDialog
                onDismiss={() => {
                    this.ExitDialog(false);
                }}
                dialogContentProps={{
                    title: (this.props.InitialModel.objectId && "Maßnahme bearbeiten") || "Maßnahme erstellen"
                }}
                maxWidth={1200}
            >
                <HandleError>
                    <PleaseWait
                        ShowSpinner={this.state.IsLoading}
                        render={() => (
                            <>
                                <ValidationMessages Messages={this.state.Item.ValidationMessages} />
                                <EasyForm
                                    Object={this.state.Item}
                                    Disabled={this.props.Model.IsReadOnly}
                                    ShowFooter={true}
                                >
                                    <Pivot>
                                        <PivotItem headerText="Allgemeines">
                                            <div className="ms-Grid" dir="ltr">
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12">
                                                        <Header>Allgemeines</Header>
                                                    </div>
                                                </div>
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm2">
                                                        <EasyNumberField
                                                            PropertyName={nameof<Knz4LSObjekt>("k4F03Kennnummer")}
                                                            Decimals={0}
                                                            TextFieldProps={{
                                                                label: "Kennnummer",
                                                                required: true,
                                                                readOnly: true
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm2">
                                                        <EasyMaskedTextField
                                                            PropertyName={nameof<Knz4LSObjekt>(
                                                                "k4F01Kurzprojektnummer"
                                                            )}
                                                            TextFieldProps={{
                                                                label: "KPN",
                                                                required: true,
                                                                mask: "99.999"
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm3">
                                                        <EasyNumberField
                                                            PropertyName={nameof<Knz4LSObjekt>(
                                                                "k4F06AnzahlWohnungseinheiten"
                                                            )}
                                                            TextFieldProps={{
                                                                label: "\u2211 Wohnungseinheiten",
                                                                required: true
                                                            }}
                                                        />
                                                    </div>

                                                    <div className="ms-Grid-col ms-sm2">
                                                        <EasyCombobox
                                                            PropertyName={nameof<Knz4LSObjekt>("k4F24Leistungsphase")}
                                                            Options={["1", "2", "3", "4", "5"]}
                                                            MultiSelect={false}
                                                            ComboBoxProps={{ label: "Leistungsstufe" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm3">
                                                        <EasyDatePicker
                                                            PropertyName={nameof<Knz4LSObjekt>("k4F21VBEingang")}
                                                            DatePickerProps={{ label: "Eingang 1. Vereinbarung" }}
                                                        />
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm3">
                                                        <EasyDatePicker
                                                            PropertyName={nameof<Knz4LSObjekt>("k4F23VBAusgang")}
                                                            DatePickerProps={{ label: "Ausgang 1. Vereinbarung" }}
                                                        />
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm3">
                                                        <EasyCombobox
                                                            PropertyName={nameof<Knz4LSObjekt>(
                                                                "k4F05Bearbeitungsstatus"
                                                            )}
                                                            Options={["verzichtet", "in Umsetzung", "fertiggestellt"]}
                                                            MultiSelect={false}
                                                            ComboBoxProps={{ label: "Status" }}
                                                        />
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm3">
                                                        <EasyCombobox
                                                            PropertyName={nameof<Knz4LSObjekt>("k4F04Forderfahigkeit")}
                                                            Options={["förderfähig", "nicht förderfähig"]}
                                                            MultiSelect={false}
                                                            ComboBoxProps={{ label: "Förderfähigkeit" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm3">
                                                        <EasyDatePicker
                                                        PropertyName={nameof<Knz4LSObjekt>("k4F21VB2Eingang")}       
                                                        DatePickerProps={{ label: "Eingang 2. Vereinbarung" }}      
                                                        /> 
                                                    </div>
                                                </div> 
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12">
                                                        <Knz4LSFirmaControl
                                                            Model={this.props.Model}
                                                            SelectedId={this.state.Item.k4F29IngRef?.objectId}
                                                            OnChange={(newId): void => {
                                                                this.state.Item.k4F29IngRef =
                                                                    new EntityReferenceOfKnz4LSIngBueroModel({
                                                                        objectId: newId,
                                                                        legacyId: undefined
                                                                    });
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12">
                                                        <EasyTextField
                                                            PropertyName={nameof<Knz4LSObjekt>("k4F26AktZeichen")}
                                                            TextFieldProps={{
                                                                label: "Aktenzeichen",
                                                                multiline: true,
                                                                autoAdjustHeight: true,
                                                                styles: { fieldGroup: { minHeight: "1em" } }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12">
                                                        <EasyTextField
                                                            PropertyName={nameof<Knz4LSObjekt>("k4F24Bemerkungen")}
                                                            TextFieldProps={{
                                                                label: "Bemerkungen",
                                                                multiline: true,
                                                                rows: 2
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <Separator>Maßnahmen</Separator>
                                                <Knz4LSBelege
                                                    Object={this.state.Item}
                                                    Disabled={this.props.Model.IsReadOnly}
                                                />
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm3">
                                                        <EasyTextField
                                                            PropertyName={nameof<Knz4LSObjekt>("k4F26AktAblage")}
                                                            TextFieldProps={{ label: "Abgelegt in Akte" }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </PivotItem>
                                        <PivotItem headerText="Objekt">
                                            <div className="ms-Grid" dir="ltr">
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12">
                                                        <Header>Objekt</Header>
                                                    </div>
                                                </div>
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm8">
                                                        <EasyTextField
                                                            PropertyName={nameof<Knz4LSObjekt>("k4F07ObjStrasse")}
                                                            TextFieldProps={{ label: "Straße" }}
                                                        />
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm2">
                                                        <EasyTextField
                                                            PropertyName={nameof<Knz4LSObjekt>("k4F09ObjNr")}
                                                            TextFieldProps={{ label: "Hausnummer" }}
                                                        />
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm2">
                                                        <EasyTextField
                                                            PropertyName={nameof<Knz4LSObjekt>("k4F10ObjErg")}
                                                            TextFieldProps={{ label: "Ergänzung" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm2">
                                                        <EasyTextField
                                                            PropertyName={nameof<Knz4LSObjekt>("k4F11ObjPlz")}
                                                            TextFieldProps={{ label: "PLZ", maxLength: 5 }}
                                                        />
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm8">
                                                        <EasyTextField
                                                            PropertyName={nameof<Knz4LSObjekt>("k4F12ObjOrt")}
                                                            TextFieldProps={{ label: "Ort" }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm6">
                                                        <EasyTextField
                                                            PropertyName={nameof<Knz4LSObjekt>("k4F13ObjOrtsteil")}
                                                            TextFieldProps={{ label: "Ortsteil" }}
                                                        />
                                                    </div>
                                                    <div className="ms-Grid-col ms-sm6">
                                                        <EasyCombobox
                                                            PropertyName={nameof<Knz4LSObjekt>("k4F13ObjBundesland")}
                                                            Options={Bundeslaender}
                                                            MultiSelect={false}
                                                            ComboBoxProps={{ label: "Bundesland", required: true }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </PivotItem>
                                        <PivotItem headerText="Eigentümer">
                                            <div className="ms-Grid" dir="ltr">
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12">
                                                        <Header>Eigentümer</Header>
                                                    </div>
                                                </div>
                                                {this.state.Item.k4F35HideDsvgo ? (
                                                    <>
                                                        <MessageBar
                                                            messageBarType={MessageBarType.info}
                                                            actions={
                                                                this.state.MayUnsetDsvgo && (
                                                                    <div>
                                                                        <MessageBarButton
                                                                            onClick={(): void => {
                                                                                mobx.runInAction(() => {
                                                                                    this.state.Item.k4F35HideDsvgo =
                                                                                        false;
                                                                                });
                                                                            }}
                                                                        >
                                                                            Eigentümerdaten einblenden
                                                                        </MessageBarButton>
                                                                    </div>
                                                                )
                                                            }
                                                        >
                                                            Die Eigentümerdaten wurden ausgeblendet. Mitarbeiter der
                                                            Rolle &quot;Fachlicher Administrator&quot; können die Daten
                                                            wieder einblenden.
                                                        </MessageBar>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm6">
                                                                <EasyTextField
                                                                    PropertyName={nameof<Knz4LSObjekt>(
                                                                        "k4F15EigVorname"
                                                                    )}
                                                                    TextFieldProps={{
                                                                        label: "Vorname",
                                                                        multiline: true,
                                                                        autoAdjustHeight: true,
                                                                        styles: { fieldGroup: { minHeight: "1em" } }
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm6">
                                                                <EasyTextField
                                                                    PropertyName={nameof<Knz4LSObjekt>(
                                                                        "k4F14EigNachname"
                                                                    )}
                                                                    TextFieldProps={{
                                                                        label: "Nachname",
                                                                        multiline: true,
                                                                        autoAdjustHeight: true,
                                                                        styles: { fieldGroup: { minHeight: "1em" } },
                                                                        required: true
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm12">
                                                                <Link
                                                                    onClick={() => {
                                                                        mobx.runInAction(() => {
                                                                            this.state.Item.CopyObjektToEigentuemer();
                                                                        });
                                                                    }}
                                                                >
                                                                    Adressdaten von Objekt übernehmen
                                                                </Link>
                                                            </div>
                                                        </div>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm8">
                                                                <EasyTextField
                                                                    PropertyName={nameof<Knz4LSObjekt>(
                                                                        "k4F16EigStrasse"
                                                                    )}
                                                                    TextFieldProps={{
                                                                        label: "Straße",
                                                                        multiline: true,
                                                                        autoAdjustHeight: true,
                                                                        styles: { fieldGroup: { minHeight: "1em" } }
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm2">
                                                                <EasyTextField
                                                                    PropertyName={nameof<Knz4LSObjekt>("k4F17EigNr")}
                                                                    TextFieldProps={{
                                                                        label: "Hausnummer",
                                                                        multiline: true,
                                                                        autoAdjustHeight: true,
                                                                        styles: { fieldGroup: { minHeight: "1em" } }
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm2">
                                                                <EasyTextField
                                                                    PropertyName={nameof<Knz4LSObjekt>("k4F18EigErg")}
                                                                    TextFieldProps={{
                                                                        label: "Ergänzung",
                                                                        multiline: true,
                                                                        autoAdjustHeight: true,
                                                                        styles: { fieldGroup: { minHeight: "1em" } }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm2">
                                                                <EasyTextField
                                                                    PropertyName={nameof<Knz4LSObjekt>("k4F19EigPlz")}
                                                                    TextFieldProps={{
                                                                        label: "PLZ",
                                                                        multiline: true,
                                                                        autoAdjustHeight: true,
                                                                        styles: { fieldGroup: { minHeight: "1em" } }
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm8">
                                                                <EasyTextField
                                                                    PropertyName={nameof<Knz4LSObjekt>("k4F20EigOrt")}
                                                                    TextFieldProps={{
                                                                        label: "Ort",
                                                                        multiline: true,
                                                                        autoAdjustHeight: true,
                                                                        styles: { fieldGroup: { minHeight: "1em" } }
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm12">
                                                                <Link
                                                                    onClick={() => {
                                                                        mobx.runInAction(() => {
                                                                            this.state.Item.k4F35HideDsvgo = true;
                                                                        });
                                                                    }}
                                                                >
                                                                    Eigentümerdaten ausblenden
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </PivotItem>
                                        <PivotItem headerText="Freigabe">
                                            <div className="ms-Grid" dir="ltr">
                                                <div className="ms-Grid-row">
                                                    <div className="ms-Grid-col ms-sm12">
                                                        <Header>Freigabe</Header>
                                                    </div>
                                                </div>
                                                {this.state.Item.k4F38FreigabeStatus ===
                                                EnumKnz4LSFreigabeStatus.Freigegeben ? (
                                                    <>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm6">
                                                                <TextField
                                                                    label="Freigegeben am"
                                                                    disabled={true}
                                                                    readOnly={true}
                                                                    value={
                                                                        this.state.Item.k4F36FreigabeAm &&
                                                                        Utils.FormatDate(
                                                                            this.state.Item.k4F36FreigabeAm
                                                                        )
                                                                    }
                                                                />
                                                            </div>
                                                            <div className="ms-Grid-col ms-sm6">
                                                                <Label>Freigegeben durch</Label>
                                                                <ShowUser
                                                                    PropertyName={nameof<Knz4LSObjekt>(
                                                                        "k4F37FreigabeUser"
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm12">
                                                                Dieser Eintrag ist noch nicht freigegeben. Die Freigabe
                                                                muss durch einen Benutzer erfolgen, der den Datensatz
                                                                nicht angelegt hat.
                                                            </div>
                                                            {this.state.MayApprove && (
                                                                <div className="ms-Grid-col ms-sm12">
                                                                    Sie können die Freigabe erteilen.
                                                                </div>
                                                            )}
                                                        </div>
                                                        <div className="ms-Grid-row">
                                                            <div className="ms-Grid-col ms-sm6">
                                                                <PrimaryButton
                                                                    onClick={() => {
                                                                        this.ApproveItem();
                                                                    }}
                                                                    text="Freigabe erteilen"
                                                                    disabled={!this.state.MayApprove}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </PivotItem>
                                        {this.props.InitialModel?.objectId && (
                                            <PivotItem headerText="Historie">
                                                <Header>Historie</Header>
                                                <RadVersionHistoryListEntity
                                                    ObjectId={this.props.InitialModel.objectId}
                                                    Repository={this.repository}
                                                />
                                            </PivotItem>
                                        )}
                                    </Pivot>
                                    <DialogFooter>
                                        <PrimaryButton
                                            onClick={() => {
                                                this.ExitDialog(true);
                                            }}
                                            text="Speichern"
                                            disabled={!this.state.Item.IsValid}
                                        />
                                        <DefaultButton
                                            onClick={() => {
                                                this.ExitDialog(false);
                                            }}
                                            text="Abbrechen"
                                        />
                                    </DialogFooter>
                                </EasyForm>
                            </>
                        )}
                    />
                </HandleError>
            </DBDialog>
        );
    }

    private async ApproveItem(): Promise<void> {
        const user = await UserRepository.GetCurrentUser();
        mobx.runInAction(() => {
            this.state.Item.k4F37FreigabeUser = new EntityReferenceOfBaseUserModel({
                objectId: user.currentUser.objectId,
                legacyId: undefined
            });
            this.state.Item.k4F36FreigabeAm = new Date();
            this.state.Item.k4F38FreigabeStatus = EnumKnz4LSFreigabeStatus.Freigegeben;
        });
    }

    @CatchReactErrorsMethod()
    private async ExitDialog(shouldSave: boolean) {
        if (shouldSave) {
            if (!this.state.Item.IsValid) {
                return;
            }
            this.setState({
                IsLoading: true
            });
        }

        await this.props.closeDialog(shouldSave, this.state.Item);
    }
}
