import debounce from "debounce";
import { observer } from "mobx-react";
import { ActionButton, SearchBox } from "office-ui-fabric-react";
import * as React from "react";
import { Knz4Repository } from "../../Model/Knz4LS";
import { Knz4LSListModelFilter } from "../../Model/Knz4LS/Knz4LSListModelFilter";
import { CatchReactErrors } from "../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class Knz4LSSearchBox extends React.Component<
    {
        OnUpdateFilter: (filter: Knz4LSListModelFilter) => void;
        Model: Knz4Repository;
        FilterText: string;
    },
    {
        filterText: string;
    }
> {
    public readonly state = { filterText: this.props.FilterText };

    private debouncedUpdate: (input: string) => void = debounce(
        (input: string) => this.props.OnUpdateFilter(new Knz4LSListModelFilter(input)),
        1000
    );

    private handleInput(input: string): void {
        this.setState({ filterText: input });
        this.debouncedUpdate(input);
    }

    public render(): JSX.Element {
        return (
            <div className="ms-Grid" dir="ltr" style={{ maxWidth: "700px", padding: "0px 0px 0px 0px" }}>
                <div className="ms-Grid-row">
                    <div className="ms-Grid-col ms-sm8" style={{ marginTop: "4px" }}>
                        <SearchBox
                            placeholder="Durchsuche Kennnummer/Straße/Stadt/Name/KPN/Aktenzeichen"
                            iconProps={{ iconName: "Filter" }}
                            onChange={(event: any, value: string) => {
                                this.handleInput(value);
                            }}
                            onClear={() => {
                                this.handleInput("");
                            }}
                            value={this.state.filterText}
                        />
                    </div>
                    <div className="ms-Grid-col ms-sm3">
                        <ActionButton
                            iconProps={{ iconName: "ClearFilter" }}
                            allowDisabledFocus={true}
                            onClick={() => {
                                this.setState({ filterText: "" });
                            }}
                        >
                            Filter zurücksetzen
                        </ActionButton>
                    </div>
                </div>
            </div>
        );
    }
}
