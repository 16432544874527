import { observer } from "mobx-react";
import { FontIcon, IColumn, ScrollablePane, ScrollbarVisibility } from "office-ui-fabric-react";
import * as React from "react";
import { EnumKnz4LSFreigabeStatus } from "../../generated/BackendClient";
import { Knz4LSObjekt } from "../../Model/Knz4LS";
import { nameof } from "../../Utils/Utils";
import { IDetailListDatasource, LargeDetailsList, ObserverSpan, PleaseWait } from "../Generic";
import { CatchReactErrors } from "../Generic/Error-Handler/Decorators";

@observer
@CatchReactErrors
export class Knz4LSListGeneric extends React.Component<
    {
        ListModelGenerator: () => IDetailListDatasource;
        OnEditItem?: (item: Knz4LSObjekt) => void;
        OnSelectedItems: (selectedItems: []) => void;
    },
    {
        ListModel: IDetailListDatasource;
    }
> {
    private listRef: React.RefObject<LargeDetailsList> = React.createRef();

    public static columns: IColumn[] = [
        {
            key: "FeldIcon01",
            name: "Freigabestatus",
            minWidth: 50,
            maxWidth: 100,
            onRender: (item: Knz4LSObjekt) => {
                return (
                    item.k4F38FreigabeStatus !== EnumKnz4LSFreigabeStatus.Freigegeben && (
                        <div style={{ display: "block", textAlign: "center" }}>
                            <FontIcon iconName="DeactivateOrders" style={{ color: "salmon", fontSize: 18 }} />
                        </div>
                    )
                );
            }
        },
        {
            key: "Feld01",
            name: "KPN",
            minWidth: 50,
            maxWidth: 50,
            isResizable: true,
            onRender: (item: Knz4LSObjekt) => <ObserverSpan Object={item} PropertyName={nameof<Knz4LSObjekt>("k4F01Kurzprojektnummer")} />
        },
        {
            key: "Feld02",
            name: "Kennnummer",
            minWidth: 50,
            maxWidth: 90,
            isResizable: true,
            onRender: (item: Knz4LSObjekt) => <ObserverSpan Object={item} PropertyName={nameof<Knz4LSObjekt>("k4F03Kennnummer")} />
        },
        {
            key: "Feld03",
            name: "Objekt Straße",
            minWidth: 100,
            maxWidth: 120,
            isResizable: true,
            onRender: (item: Knz4LSObjekt) => <ObserverSpan Object={item} PropertyName={nameof<Knz4LSObjekt>("k4F07ObjStrasse")} />
        },
        {
            key: "Feld04",
            name: "Objekt Hausnr",
            minWidth: 50,
            maxWidth: 80,
            isResizable: true,
            onRender: (item: Knz4LSObjekt) => <ObserverSpan Object={item} PropertyName={nameof<Knz4LSObjekt>("k4F09ObjNr")} />
        },
        {
            key: "Feld05",
            name: "Objekt Ergänzung",
            minWidth: 50,
            maxWidth: 120,
            isResizable: true,
            onRender: (item: Knz4LSObjekt) => <ObserverSpan Object={item} PropertyName={nameof<Knz4LSObjekt>("k4F10ObjErg")} />
        },
        {
            key: "Feld06",
            name: "Eigentümer Nachname",
            minWidth: 100,
            maxWidth: 200,
            isResizable: true,
            onRender: (item: Knz4LSObjekt) => <ObserverSpan Object={item} PropertyName={nameof<Knz4LSObjekt>("k4F14EigNachname")} />
        }
    ];

    public readonly state = { ListModel: undefined };

    public async RefreshListItems(): Promise<void> {
        await this.listRef?.current?.RefreshItems();
    }

    public componentDidMount(): void {
        this.setState({ ListModel: this.props.ListModelGenerator() });
    }

    public render(): JSX.Element {
        return (
            <div style={{ position: "relative", height: "80vh" }}>
                <ScrollablePane scrollbarVisibility={ScrollbarVisibility.auto}>
                    <PleaseWait
                        ShowSpinner={!this.state.ListModel}
                        render={() => (
                            <LargeDetailsList
                                Columns={Knz4LSListGeneric.columns}
                                DataSource={this.state.ListModel}
                                OnItemInvoked={this.props.OnEditItem}
                                OnSelectedItems={this.props.OnSelectedItems}
                                ref={this.listRef}
                            />
                        )}
                    />
                </ScrollablePane>
            </div>
        );
    }
}
